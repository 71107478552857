<template>
	<div class="post">

		<article v-if="post" :class="$helpers.fontStackClass(post.language)">

			<header>
				<h1>
					{{post.title}}
				</h1>

				<div class="byline font-face-lt">
					<time :datetime="$moment(post.created_at).format('YYYY-Do-MMM')" :title="$moment(post.created_at).format('dddd, MMMM Do YYYY')">{{ post.created_at | moment("D/M/Y")}}</time>
					by
					<address>
						<router-link :to="{ name: 'profile', params: { username: post.author.data.username }}">{{post.author.data.username}}</router-link>
					</address>
				</div>
			</header>

			<div class="content" v-html="post.html"></div>

		</article>


		<hr>

		<section class="actions">


			<div class="">
				<a class="button" role="button" href="javascript:undefined" v-if="!showCorrectionInterface && $store.getUser()" @click="showAddCorrection">Add correction</a>
				<a class="button" role="button" href="javascript:undefined" v-if="showCorrectionInterface" @click="showCorrectionInterface=false">Hide</a>
			</div>


		</section>



		<section class="corrections">

			<div class="correctionInterface" v-show="showCorrectionInterface">
				<MarkdownEditor v-model="correction.text" class="editor" autofocus/>
				<textarea name="correction" rows="4" cols="80" placeholder="Do you have a comment for your edit?" v-model="correction.comment"></textarea>

				<button type="submit" variant="primary" @click="publishCorrection">Publish</button>
			</div>


			<article class="correction" v-for="correction in corrections" :key="correction.key" :class="$helpers.fontStackClass(post.language)">

				<header>
					<div class="byline font-face-lt">
						<time :datetime="$moment(correction.created_at).format('YYYY-Do-MMM')" :title="$moment(correction.created_at).format('dddd, MMMM Do YYYY')">{{ correction.created_at | moment("D/M/Y")}}</time>
						by
						<address>
							<router-link :to="{ name: 'profile', params: { username: correction.author.data.username }}">{{correction.author.data.username}}</router-link>
						</address>
						<a class="button" role="button" href="javascript:undefined" @click="hideErrors = !hideErrors">{{ hideErrors ? "Show errors" : "Hide errors" }}</a>
					</div>

				</header>


				<div class="content" :class="{'hide-errors': hideErrors}" v-html="correction.html"></div>
				<div class="comment font-face-lt" v-html="correction.comment"></div>

			</article>
		</section>


	</div>
</template>

<script>
// @ is an alias to /src
// import Actionable from '@/components/Actionable.vue'
import MarkdownEditor from '@voraciousdev/vue-markdown-editor'

export default {
	name: 'Post',
	components: {
		MarkdownEditor
		// Actionable
	},
	data() {
		return {
			post: null,
			corrections: null,

			showCorrectionInterface: false,
			hideErrors: false,
			correction: {
				text: '',
				comment: '',
			}
		}
	},
	computed: {

	},
	mounted() {

		this.key = this.$route.params.key

		this.$api.get('/posts/'+this.key)
		.then((response) => {
			this.post = response.data.data;
		})
		.catch((response) => {
			console.log(response);
		});

		this.$api.get('/posts/'+this.key+'/corrections')
		.then((response) => {

			this.corrections = response.data.data;

		})
		.catch((response) => {
			console.log(response);
		});


	},
	methods: {
		showAddCorrection() {
			this.correction.text = this.post.markdown;
			this.showCorrectionInterface = true;
		},

		publishCorrection() {
			if( !confirm("Are you sure? Corrections cannot be edited once published.") ) return;

			this.$api.post('/posts/'+this.post.key+'/corrections', {
				markdown: this.correction.text,
				comment: this.correction.comment
			})
			.then((response) => {
				let c = response.data.data;

				this.corrections.unshift(c);

				this.correction.text = '';
				this.correction.comment = '';
				this.showCorrectionInterface = false;
			})
			.catch((response) => {
				console.log(response);
			});
		}


		// onCorrect(from, to) {
		// 	console.log(from);
		// 	console.log(to);
		//
		// 	this.publishCorrection(this.key, from, to);
		// },
		//
		// publishCorrection(key, from, to) {
		//
		// 	this.$api.post('/posts/'+key+'/corrections/', {
		// 		from: from,
		// 		to: to
		// 	})
		// 	.then((response) => {
		// 		console.log(response);
		// 	})
		// 	.catch((response) => {
		// 		console.log(response);
		// 	});
		// }

	}
}
</script>


<style lang="scss" >

.post {

}


.post .content .selection {
	// background-color: #a8d1ff;
	padding: 5px 0;
}
.post .content .error {
	text-decoration: line-through;
	color: #B02E0C;
}
.post .content .correction {
	color: #47A025;
	// color: #0075A2;
}



.post .correctionInterface {
	margin-top: 2rem;
	margin-bottom: 4rem;


	.editor {
		background-color: #050505;
		border-radius: 0.25em;
		padding: 1em;
		margin-bottom:1rem;
		width: 100%;
		box-sizing: border-box;
		// font-family: '华文细黑', 'STXihei', 'PingFang TC', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑', 'Microsoft Yahei', '宋体', 'SimSun', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !important;
	}

	textarea {
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		resize: vertical;
		min-height: 50px;
		margin-bottom:1rem;
		padding: 1em;
		font-size: 1em;
		font-family: '华文细黑', 'STXihei', 'PingFang TC', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑', 'Microsoft Yahei', '宋体', 'SimSun', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !important;
	}
}



.post section.actions {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	a {
		display: inline-block;
		margin-right: 1em;
	}

}

.post section.corrections {

	article.correction {
		margin-top: 5rem;

		// &:first-of-type {
		// 	margin-top: 2rem;
		// }

		&.font-face-ar {
			.byline {
				text-align: right;

				.button {
					left:0;
					right: auto;
				}
			}
		}

		.hide-errors del {
			display: none;
		}

		.byline {
			font-size: 1rem;
			position: relative;

			.button {
				right:0;
				position: absolute;
			}
		}
		.content {
			margin-top: 0;

			ins {
				color: #47A025;
				text-decoration: none;
			}
			del {
				color: #B02E0C;
				margin-right: 0.1rem;
			}
		}

		.comment {
			font-size: 1rem;
		}
	}

}


.post article {

	margin-bottom: 10rem;

	h1 {
		font-weight: 400;
		margin-top: 4rem;
		margin-bottom: 1.5rem;
		font-size: 3.2rem;
		line-height: 1;
	}

	.byline {
		// font-family: 'Ubuntu', sans-serif;
		font-size: 1.4rem;
		font-style: italic;
	}
	.byline address {
		display: inline;
	}



	.content {
		margin-top: 4em;

		h2 {
			font-style: italic;
			font-weight: 400;
			margin-top: 2.1rem;
			margin-bottom: 1.4rem;
			font-size: 2.2rem;
			line-height: 1;
		}
		h3 {
			font-style: italic;
			font-weight: 400;
			font-size: 1.7rem;
			margin-top: 2rem;
			margin-bottom: 1.4rem;
			line-height: 1;
		}
		ul + h2,
		ol + h2,
		p + h2,
		pre + h2,
		div + h2,
		blockquote + h2 {
			margin-top: 5rem;
		}
		ul + h3,
		ol + h3,
		p + h3,
		pre + h3,
		div + h3,
		blockquote + h3 {
			margin-top: 2rem;
		}
		p, dl, ol, ul {
			font-size: 1.4rem;
			line-height: 2.0rem;
		}
		li {
			margin-bottom: 0.4em;
		}
		img {
			max-width: 80%;
			display: block;
			margin: 2em auto;
		}
		.katex {
			font-size: 1.4rem;
		}
		blockquote {
			// font-family: 'IBM Plex Serif', serif;
			font-size: 1.0rem;
		}
		blockquote p {
			font-size: 1.0em;
		}
		li blockquote {
			margin: 0 0 0 1em;
		}
		table {
			width: 100%;
			font-size: 1.4rem;
			margin: 2em auto;
		}
		thead th {
			text-align: left;
			padding-bottom: 0.5em;
		}
		input[type=checkbox] {
			margin-right: 0.7em;
		}



	}


}




</style>
