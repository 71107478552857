<template>
    <div class="posts">



        <header>
            <h1>{{name}} (@{{username}})</h1>
            <p>Joined: {{created_at | moment("dddd, MMMM Do YYYY")}}</p>
        </header>

        <section v-if="posts.length">

            <!-- <h2>Entries</h2> -->

            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>🌐</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="post in posts" :key="post.key">
                        <td>{{post.created_at | moment("D/M/Y")}}</td>
                        <td><router-link :to="{ name: 'post', params: { username: user.username, key: post.key }}">{{post.title}}</router-link></td>
                        <td><lang-flag :lang="post.language" /></td>
                    </tr>
                </tbody>
            </table>

        </section>

        <section v-if="corrections.length">

            <h2>Corrections</h2>
            <p>Corrections written by @{{username}}</p>

            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Post</th>
                        <th>Language</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="post in corrections" :key="post.key">
                        <td>{{post.created_at | moment("D/M/Y")}}</td>
                        <td><router-link :to="{ name: 'post', params: { username: user.username, key: post.key }}">{{post.title}}</router-link></td>
                        <td><lang-flag :lang="post.language" /></td>
                    </tr>
                </tbody>
            </table>

        </section>



    </div>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'Posts',
    components: {

    },
    data() {
        return {
            username: '',
            user: null,
            posts: [],
            corrections: []
        }
    },
    computed: {
        name: function() {
            return this.user ? this.user.name : "...";
        },
        created_at: function() {
            return this.user ? this.user.created_at : "";
        }
    },
    mounted() {
        this.username = this.$route.params.username

        this.$api.get('/users/'+this.username)
        .then((response) => {

            this.user = response.data.data;
            this.posts = this.user.posts.data;
        })
        .catch((response) => {
            console.log(response);
        });

        this.$api.get('/users/'+this.username+'/corrections')
        .then((response) => {
            this.corrections = response.data.data
        })
        .catch((response) => {
            console.log(response);
        });


    },
    methods: {

    }
}
</script>


<style lang="scss" scoped>


section {
    margin-top: 5em;
}

table {
    width: 100%;
    text-align: left;
}
table tr td:last-child,
table tr th:last-child {
    text-align: right;
}

</style>
